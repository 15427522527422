/* eslint-disable @typescript-eslint/explicit-function-return-type */
import * as moment from 'moment';
import { Injectable } from '@angular/core';
import * as Highcharts from 'highcharts/highstock';
import { Store } from '@ngrx/store';

import {
  ISeriesOptions,
  IEventsChart,
  ITrendsChart,
  PowersideYAxisOptions,
} from 'src/app/_shared/classes/chart.interface';
import { Channel } from 'src/app/_shared/interface/channel/channel.interface';
import * as fromUser from 'src/app/_store/_reducers';
import { AlarmEventNoteResponse } from 'src/app/_shared/interface/alarm/alarm-event-note-request.interface';
import { DriveState } from 'src/app/_shared/enum/drive-state.enum';

import { PQEvents } from 'src/app/_shared/services/chart-definitions/charts/pq-events';
import { TrendsChart } from 'src/app/_shared/services/chart-definitions/charts/trend-chart';
import { EventSeriesOptions } from 'src/app/_shared/services/chart-definitions/options/event-series-options';
import { SeriesOptions } from 'src/app/_shared/services/chart-definitions/options/series-options';

export { PQEvents, EventSeriesOptions, TrendsChart, SeriesOptions };

class AdvancedChart extends TrendsChart {
  private mPhase: string;

  public set phase(phase: string) {
    this.mPhase = phase;
  }

  public get phase() {
    return this.mPhase;
  }

  private generateChannelIds(channel: string, phase: string): string {
    const id = channel.replace(/\D+/g, '');
    const fSymbol = channel.indexOf(id);
    const newChannel = parseInt(id, 10) + parseInt(phase.replace(/\D+/g, ''), 10);

    return channel.slice(0, fSymbol) + newChannel + channel.slice(fSymbol + id.length, channel.length);
  }

  public setName(phase: string): string[] {
    return this.seriesArray.map((series) => {
      return (series.name = phase);
    });
  }

  public replaceChannelIds(phase: string): string[] {
    return this.seriesArray.map((series) => {
      return (series.channelIds[0] = this.generateChannelIds(series.channelIdBaseArray[0], phase));
    });
  }

  constructor(name: string, csvHeaders: string[][], series: SeriesOptions[], phase: string) {
    // phase: string
    super(name, csvHeaders, series);
    this.phase = phase;
    this.replaceChannelIds(this.phase);
    this.setName(this.phase);
    this.yAxisOptions[0].title.text =
      this.name + '<p style="width: 100%; text-align: center; margin: 0; padding: 0">' + '(' + this.phase + ')</p>';
    this.yAxisOptions[0].title.useHTML = true;
  }
}

export function replaceAdvancedChart(name, csvHeaders, series, phase) {
  return new AdvancedChart(name, csvHeaders, series, phase);
}

class TrendAlarmOptions implements ISeriesOptions {
  tooltip: Highcharts.TooltipOptions = {
    valueDecimals: 4,
  };
  data: number[][] = [];
  type: string = 'line';
  color: string;
  name: string;
  yAxis: number = 1;
  yValue: number = 1;
  id: string;
  dataGrouping = { enabled: false };
  trendType: string;
  serieType = 'alarm';
  lineWidth: number;
  channelIds: string[];

  constructor(element: any, gran, interval, startDate, endDate) {
    this.color = element.severity === 1 ? '#811717' : '#FF8001';
    this.name = element.typeName;
    this.id = element.id.toString();
    this.trendType = element.severity === 1 ? 'alarm' : 'warning';
    Object.assign(this.tooltip, {});
    this.data = [];

    let time;
    if (moment(startDate).tz(element.timezone, false).isBefore(element.triggeredWhen)) {
      time = moment(element.triggeredWhen).valueOf();
    } else {
      time = moment(startDate).valueOf();
    }

    let endLoopTime;
    let now = moment().tz(element.timezone, false);
    let minutes = now.minute();
    let subs = minutes % 10 < 2 ? 11 + (minutes % 10) : 1 + (minutes % 10);
    now.subtract(subs, 'minutes');

    if (moment(element.endDateTime).tz(element.timezone, false).isBefore(endDate)) {
      endLoopTime = moment(element.endDateTime);
    } else {
      endLoopTime = moment(endDate);
    }

    if (interval === 10 && gran === 'minute') {
      if ((moment(time).minute() % 10 < 5 && moment(time).minute() !== 0) || element.duration < 600000) {
        time = moment(time)
          .subtract(moment(time).minute() % 10, 'minute')
          .valueOf();
      } else if (moment(time).minute() % 10 >= 5 && moment(time).minute() % 10 !== 0) {
        time = moment(time)
          .add(10 - (moment(time).minute() % 10), 'minute')
          .valueOf();
      }

      if (moment(endLoopTime).minute() % 10 < 5 && moment(endLoopTime).minute() !== 0) {
        endLoopTime = moment(endLoopTime)
          .subtract(moment(endLoopTime).minute() % 10, 'minute')
          .valueOf();
      } else if (
        (moment(endLoopTime).minute() % 10 >= 5 && moment(endLoopTime).minute() % 10 !== 0) ||
        element.duration < 600000
      ) {
        endLoopTime = moment(endLoopTime)
          .add(10 - (moment(endLoopTime).minute() % 10), 'minute')
          .valueOf();
      }
    } else if (interval === 1 && gran === 'day') {
      time = moment(time).tz(element.timezone).startOf('day').valueOf();
      endLoopTime = moment(endLoopTime).tz(element.timezone).add(1, 'day').startOf('day').valueOf();
    }

    while (time < endLoopTime.valueOf() && time < now.utc(false).valueOf()) {
      this.data.push([moment(time).utc(false).valueOf(), element.severity]);
      time = moment(time).utc(false).add(interval, gran).valueOf();
    }

    if (endLoopTime.valueOf() < now.valueOf()) {
      this.data.push([endLoopTime.valueOf(), element.severity]);
    }

    this.lineWidth = 10;
  }
}

export class DynamicTrendsAlarm implements IEventsChart {
  private mYAxis;
  private mName: string;
  private mCsvHeaders: string[][];
  private mSeriesArray: TrendAlarmOptions[];
  private mYAxisOptions: PowersideYAxisOptions[];
  private mSpacer = 5;
  public id: string;
  yAxisOptions;
  name;

  public set yAxis(newYAxis: number) {
    this.mYAxis = this.seriesArray.forEach((series) => (series.yAxis = newYAxis));
  }
  public get seriesArray(): any[] {
    return this.mSeriesArray;
  }
  public get spacer(): number {
    return this.mSpacer;
  }

  public set spacer(gap: number) {
    this.mSpacer = gap;
  }

  constructor() {
    this.mYAxis = null;
    (this.mName = 'trendAlarm'), (this.mSeriesArray = []);
    this.mYAxisOptions = [];

    this.yAxisOptions = [
      {
        visible: true,
        tickPositions: [0, 1],
        labels: {
          enabled: false,
        },
        height: 14,
        lineWidth: 1,
        resize: {
          enabled: false,
        },
        offset: 0,
        plotBands: [
          {
            acrossPanes: true,
            color: '#F0F5F7',
            from: 0,
            to: 1,
          },
        ],
      },
    ];
  }

  // initial load of data, also sets expected intervals of data points
  setSeriesData(
    isInsite: boolean,
    channelDataArray: any,
    eventsDataArray: AlarmEventNoteResponse[],
    alarmsDataArray: AlarmEventNoteResponse[],
    granularity: string,
    interval?: number,
    startDate?: any,
    endDate?: any
  ): any[] {
    alarmsDataArray.forEach((element) => {
      this.seriesArray.push(new TrendAlarmOptions(element, granularity, interval, startDate, endDate));
    });
    return this.seriesArray;
  }

  // empties chart data at the series level
  clearSeriesData(): void {
    this.mSeriesArray = [];
  }
}

class Dynamic extends TrendsChart {
  constructor(channel: Channel) {
    if (channel.associatedChannels) {
      let minScale;
      let maxScale;
      const seriesOpt = [];
      Object.keys(channel.associatedChannels).forEach((element) => {
        // if (channel.associatedChannels[element].displayScaleMin) {
        //   minScale = parseFloat(channel.associatedChannels[element].displayScaleMin);
        // }

        // if (channel.associatedChannels[element].displayScaleMax) {
        //   maxScale = parseFloat(channel.associatedChannels[element].displayScaleMax);
        // }

        let table = 'oneminute';
        let avg: string;
        let min: string;
        let max: string;
        let label = ' Avg';

        if (!channel.associatedChannels[element].trendTable.oneminute) {
          table = 'tenminute';
          avg = channel.associatedChannels[element].trendTable.tenminute.filter((p) => p.includes('avg'))[0];
          if (!avg) {
            avg = channel.associatedChannels[element].trendTable.tenminute.filter((p) => p.includes('rms'))[0];
            label = ' Rms';
          }
        } else {
          avg = channel.associatedChannels[element].trendTable.oneminute.filter((p) => p.includes('avg'))[0];
          min = channel.associatedChannels[element].trendTable.oneminute.filter((p) => p.includes('min'))[0];
          max = channel.associatedChannels[element].trendTable.oneminute.filter((p) => p.includes('max'))[0];
          if (!avg) {
            console.error(
              'No AVG channels found in the trendTable.oneminute',
              channel.associatedChannels[element].trendTable.oneminute
            );
          }
        }

        if (min && max) {
          seriesOpt.push(
            new SeriesOptions(
              'arearange',
              channel.associatedChannels[element].altChartColor,
              'Min/Max',
              [min, max],
              {
                valueSuffix: ' ' + channel.associatedChannels[element].units,
                valueDecimals: channel.associatedChannels[element].maxDecimalDigits,
              },
              table,
              channel.associatedChannels[element].name
            )
          );
        }

        if (avg) {
          seriesOpt.push(
            new SeriesOptions(
              'line',
              channel.associatedChannels[element].chartColor,
              label,
              [avg],
              {
                valueSuffix: ' ' + channel.associatedChannels[element].units,
                valueDecimals: channel.associatedChannels[element].maxDecimalDigits,
              },
              table,
              channel.associatedChannels[element].name
            )
          );
        }
      });
      super(channel.name, [[channel.name]], [...seriesOpt]);
      // this.setMinMax(minScale, maxScale);
    } else {
      let table = 'oneminute';
      let min: string;
      let max: string;
      let avg: string;
      let rmsChannel: string = null;

      if (!channel.trendTable.oneminute) {
        min = channel.trendTable.tenminute.find((channels) => channels.includes('min'));
        max = channel.trendTable.tenminute.find((channels) => channels.includes('max'));
        avg = channel.trendTable.tenminute.find((channels) => channels.includes('avg'));
        table = 'tenminute';
      } else {
        min = channel.trendTable.oneminute.find((channels) => channels.includes('min'));
        max = channel.trendTable.oneminute.find((channels) => channels.includes('max'));
        avg = channel.trendTable.oneminute.find((channels) => channels.includes('avg'));
      }

      if (min && max) {
        super(
          channel.name,
          [[channel.name]],
          [
            new SeriesOptions(
              'arearange',
              channel.altChartColor,
              'Min/Max',
              [min, max],
              {
                valueSuffix: ' ' + channel.units,
                valueDecimals: channel.maxDecimalDigits,
              },
              table
            ),
            new SeriesOptions(
              'line',
              channel.chartColor,
              ' Avg',
              [avg],
              {
                valueSuffix: ' ' + channel.units,
                valueDecimals: channel.maxDecimalDigits,
              },
              table
            ),
          ]
        );
      } else {
        let tooltip: Highcharts.TooltipOptions;
        let name;

        // Add case for a custom channel
        switch (channel.name.toLowerCase()) {
          case 'drive state': {
            tooltip = {
              pointFormatter: function () {
                return (
                  '<span style="color:' +
                  this.color +
                  '">\u25CF</span>' +
                  this.series.name +
                  ': <b>' +
                  DriveState[this.y] +
                  '</b>'
                );
              },
            };
            name = ' State';
            break;
          }

          case 'internal temperature': {
            tooltip = {
              valueSuffix: ' ' + channel.units,
              valueDecimals: channel.maxDecimalDigits,
            };
            name = ' Temperature';
            break;
          }

          default: {
            tooltip = {
              valueSuffix: ' ' + channel.units,
              valueDecimals: channel.maxDecimalDigits,
            };
            name = ' Rms';
            rmsChannel = !channel.trendTable.oneminute
              ? channel.trendTable.tenminute.filter((p) => p.includes('rms'))[0]
              : channel.trendTable.oneminute.filter((p) => p.includes('rms'))[0];
            break;
          }
        }

        super(
          channel.name,
          [[channel.name]],
          [
            new SeriesOptions(
              'line',
              '#0087FF',
              name,
              rmsChannel
                ? [rmsChannel]
                : table === 'oneminute'
                ? channel.trendTable.oneminute
                : channel.trendTable.tenminute,
              tooltip,
              table
            ),
          ]
        );
      }
      // this.setMinMax(parseFloat(channel.displayScaleMin), parseFloat(channel.displayScaleMax));
    }
  }

  public setYMinMax(min: number, max: number): void {
    super.setYMinMax(min, max);
  }

  public setYMin(min: number): void {
    super.setYMin(min);
  }

  public setYMax(max: number): void {
    super.setYMax(max);
  }

  private setMinMax(min: number, max: number): void {
    if (min && max) {
      this.setYMinMax(min, max);
    } else if (min) {
      this.setYMin(min);
    } else if (max) {
      this.setYMax(max);
    }
  }
}

class Dashboard extends TrendsChart {
  constructor() {
    super(
      'Dashboard',
      [['Power (kW)'], ['Reactive Power 1/2 Cycle Avg (kvar)']],
      [
        // TODO channel should be changed when provided by Powerside
        new SeriesOptions('area', '#0087FF', 'Power', ['c_70_avg_w'], {
          // eslint-disable-next-line space-before-function-paren
          pointFormatter: function () {
            return this.series.name + ': ' + Highcharts.numberFormat(this.y / 1000, 2) + ' (kW)';
          },
        }),
        new SeriesOptions('area', '#FF5003', 'Waste', ['pc_2_avg_none'], {
          // eslint-disable-next-line space-before-function-paren
          pointFormatter: function () {
            return this.series.name + ': ' + Highcharts.numberFormat(this.y / 1000, 2) + ' (kvar)';
          },
        }),
      ]
    );
  }
}

class Dashboard1Phase extends TrendsChart {
  constructor() {
    super(
      'Dashboard',
      [['L1 Power']],
      [
        // TODO channel should be changed when provided by Powerside
        new SeriesOptions('area', '#0087FF', 'L1 Power', ['c_71_avg_w'], {
          // eslint-disable-next-line space-before-function-paren
          pointFormatter: function () {
            return this.series.name + ': ' + Highcharts.numberFormat(this.y / 1000, 2) + ' (kW)';
          },
        }),
      ]
    );
  }
}

class DashboardCustom extends TrendsChart {
  constructor(channel) {
    super(
      'Dashboard Custom',
      [['Text 1'], ['Text 2']],
      [
        new SeriesOptions(
          'area',
          '#202F44',
          channel?.channelName,
          [channel?.sourceChannel],
          {
            valueSuffix: ' ' + channel?.units,
            valueDecimals: 3,
          },
          channel?.sourceTable
        ),
      ]
    );
  }
}

class ActiveEnergy extends TrendsChart {
  constructor() {
    super(
      'Active Energy (kWh)',
      [['Total Active Energy Per Interval Avg (kWh)']],
      [
        new SeriesOptions('line', '#0087FF', ' Avg', ['pc_1_avg_kwhr'], {
          valueSuffix: ' kWh',
          valueDecimals: 0,
        }),
      ]
    );
  }
}

class ReactiveEnergy extends TrendsChart {
  constructor() {
    super(
      'Reactive Energy (kVARh)',
      [['Total Reactive Energy per Interval Avg (kVARh)']],
      [
        new SeriesOptions('line', '#0087FF', 'Avg', ['pc_3_avg_varhr'], {
          valueSuffix: ' kVARh',
          valueDecimals: 0,
        }),
      ]
    );
  }
}

class TotalActivePower extends TrendsChart {
  constructor() {
    super(
      'Total Active Power (kW)',
      [
        ['Total Active Power 1/2-Cycle Max(W)'],
        ['Total Active Power 1/2-Cycle Min(W)', 'Total Active Power 1/2-Cycle Max(W)'],
      ],
      [
        new SeriesOptions('arearange', 'rgba(153, 198, 253, .3)', 'Min/Max', ['c_70_min_w', 'c_70_max_w'], {
          valueSuffix: ' W',
          valueDecimals: 0,
        }),
        new SeriesOptions('line', '#0050BF', 'Avg', ['c_70_avg_w'], {
          valueSuffix: ' W',
          valueDecimals: 0,
        }),
      ]
    );
  }
}

class ReactivePower extends TrendsChart {
  constructor() {
    super(
      'Reactive Power',
      [['Reactive Power 1'], ['Reactive Power 2'], ['Reactive Power 3']],
      [
        new SeriesOptions('line', '#FF515B', 'L1', ['c_89_avg_var'], {
          valueSuffix: ' var',
          valueDecimals: 0,
        }),
        new SeriesOptions('line', '#FFBE08', 'L2', ['c_90_avg_var'], {
          valueSuffix: ' var',
          valueDecimals: 0,
        }),
        new SeriesOptions('line', '#0050BF', 'L3', ['c_91_avg_var'], {
          valueSuffix: ' var',
          valueDecimals: 0,
        }),
      ]
    );
  }
}

class PowerFactor extends TrendsChart {
  constructor() {
    super(
      'Power Factor',
      [['Power Factor 1'], ['Power Factor 2'], ['Power Factor 3']],
      [
        new SeriesOptions('line', '#FF515B', 'L1', ['c_125_avg_none'], {
          valueDecimals: 3,
        }),
        new SeriesOptions('line', '#FFBE08', 'L2', ['c_126_avg_none'], {
          valueDecimals: 3,
        }),
        new SeriesOptions('line', '#0050BF', 'L3', ['c_127_avg_none'], {
          valueDecimals: 3,
        }),
      ]
    );
  }
}

class ActivePower extends TrendsChart {
  constructor() {
    super(
      'Active Power',
      [['Active Power 1'], ['Active Power 2'], ['Active Power 3']],
      [
        new SeriesOptions('line', '#FF515B', 'L1', ['c_71_avg_w'], {
          valueSuffix: ' W',
          valueDecimals: 0,
        }),
        new SeriesOptions('line', '#FFBE08', 'L2', ['c_72_avg_w'], {
          valueSuffix: ' W',
          valueDecimals: 0,
        }),
        new SeriesOptions('line', '#0050BF', 'L3', ['c_73_avg_w'], {
          valueSuffix: ' W',
          valueDecimals: 0,
        }),
      ]
    );
  }
}

class TotalReactivePower extends TrendsChart {
  constructor() {
    super(
      'Total Reactive Power',
      [
        ['Total Reactive Power 1/2-Cycle Avg(kVAR)'],
        ['Total Reactive Power 1/2-Cycle Min(kVAR)', 'Total Reactive Power 1/2-Cycle Max(kVAR)'],
      ],
      [
        new SeriesOptions('arearange', 'rgba(153, 198, 253, .3)', 'Min/Max', ['c_88_min_var', 'c_88_max_var'], {
          valueSuffix: ' VAR',
          valueDecimals: 0,
        }),
        new SeriesOptions('line', '#0050BF', 'Avg', ['c_88_avg_var'], {
          valueSuffix: ' VAR',
          valueDecimals: 0,
        }),
      ]
    );
  }
}

class ApparentPower extends TrendsChart {
  constructor() {
    super(
      'Apparent Power',
      [
        ['Apparent Power 1/2-Cycle Avg(kVA)'],
        ['Apparent Power 1/2-Cycle Min(kVA)', 'Apparent Power 1/2-Cycle Max(kVA)'],
      ],
      [
        new SeriesOptions('arearange', 'rgba(153, 198, 253, .3)', 'Min/Max', ['c_106_min_va', 'c_106_max_va'], {
          valueSuffix: ' VA',
          valueDecimals: 0,
        }),
        new SeriesOptions('line', '#0050BF', 'Avg', ['c_106_avg_va'], {
          valueSuffix: ' VA',
          valueDecimals: 0,
        }),
      ]
    );
  }
}

class TotalPowerFactor extends TrendsChart {
  constructor() {
    super(
      'Total Power Factor',
      [['tPF 1/2-Cycle Avg'], ['tPF 1/2-Cycle Min', 'tPF 1/2-Cycle Min']],
      [
        new SeriesOptions('arearange', 'rgba(153, 198, 253, .3)', 'Min/Max', ['pc_4_min_none', 'pc_6_max_none'], {
          valueDecimals: 3,
        }),
        new SeriesOptions('line', '#0050BF', 'Avg', ['pc_5_avg_none'], {
          valueDecimals: 3,
        }),
      ]
    );

    super.setYMinMax(0, 1.1);
  }
}

class VoltageZeroSequenceUnbalance extends TrendsChart {
  constructor() {
    super(
      'Voltage Zero Sequence Unbalance',
      [
        ['Voltage Zero Sequence Unbalance Avg(%)'],
        ['Voltage Zero Sequence Unbalance Min(%)', 'Voltage Zero Sequence Unbalance Max(%)'],
      ],
      [
        new SeriesOptions('arearange', 'rgba(153, 198, 253, .3)', 'Min/Max', ['c_289_min_%', 'c_289_max_%'], {
          valueSuffix: ' %',
          valueDecimals: 2,
        }),
        new SeriesOptions('line', '#0050BF', 'Avg', ['c_289_avg_%'], {
          valueSuffix: ' %',
          valueDecimals: 2,
        }),
      ]
    );

    super.setYMinMax(0, 5);
  }
}

class CurrentZeroSequenceUnbalance extends TrendsChart {
  constructor() {
    super(
      'Current Zero Sequence Unbalance',
      [
        ['Current Zero Sequence Unbalance Avg(%)'],
        ['Current Zero Sequence Unbalance Min(%)', 'Current Zero Sequence Unbalance Max(%)'],
      ],
      [
        new SeriesOptions('arearange', 'rgba(153, 198, 253, .3)', 'Min/Max', ['c_290_min_%', 'c_290_max_%'], {
          valueSuffix: ' %',
          valueDecimals: 2,
        }),
        new SeriesOptions('line', '#0050BF', 'Avg', ['c_290_avg_%'], {
          valueSuffix: ' %',
          valueDecimals: 2,
        }),
      ]
    );

    super.setYMinMax(0, 50);
  }
}

class VoltageLNRMSMagnitude extends TrendsChart {
  constructor() {
    super(
      'Voltage L-N RMS Magnitude',
      [['L-N RMS 1/2 (1-cyc) Avg(Volts)'], ['L-N RMS 1/2 (1-cyc) Min(Volts)', 'L-N RMS 1/2 (1-cyc) Max(Volts)']],
      [
        new SeriesOptions('arearange', 'rgba(153, 198, 253, .3)', 'Min/Max', ['c_1597_min_v', 'c_1597_max_v'], {
          valueSuffix: ' Volts',
          valueDecimals: 1,
        }),
        new SeriesOptions('line', '#0050BF', 'Avg', ['c_1597_avg_v'], {
          valueSuffix: ' Volts',
          valueDecimals: 1,
        }),
      ]
    );
    super.yAxisOptions[0].softMin = undefined;
    super.yAxisOptions[0].softMax = undefined;

    // this.yAxisOptions.min = 95;
    // this.yAxisOptions.max = 105;
  }
}

class VoltageLLRMSMagnitude extends TrendsChart {
  constructor() {
    super(
      'Voltage L-L RMS Magnitude',
      [['L-L RMS 1/2 (1-cyc) Avg(Volts)'], ['L-L RMS 1/2 (1-cyc) Min(Volts)', 'L-L RMS 1/2 (1-cyc) Max(Volts)']],
      [
        new SeriesOptions('arearange', 'rgba(153, 198, 253, .3)', 'Min/Max', ['c_1598_min_v', 'c_1598_max_v'], {
          valueSuffix: ' Volts',
          valueDecimals: 1,
        }),
        new SeriesOptions('line', '#0050BF', 'Avg', ['c_1598_avg_v'], {
          valueSuffix: ' Volts',
          valueDecimals: 1,
        }),
      ]
    );
    super.yAxisOptions[0].softMin = undefined;
    super.yAxisOptions[0].softMax = undefined;
    // this.yAxisOptions.min = 95;
    // this.yAxisOptions.max = 105;
  }
}

class CurrentMagnitudeAndVariations extends TrendsChart {
  constructor() {
    super(
      'Current RMS Magnitude',
      [
        ['Current RMS 1/2 (1-cyc) Avg(Amps)'],
        ['Current RMS 1/2 (1-cyc) Min(Amps)', 'Current RMS 1/2 (1-cyc) Max(Amps)'],
      ],
      [
        new SeriesOptions('arearange', 'rgba(153, 198, 253, .3)', 'Min/Max', ['c_1599_min_a', 'c_1599_max_a'], {
          valueSuffix: ' Amps',
          valueDecimals: 1,
        }),
        new SeriesOptions('line', '#0050BF', 'Avg', ['c_1599_avg_a'], {
          valueSuffix: ' Amps',
          valueDecimals: 1,
        }),
      ]
    );
  }
}

class FlickerPinst extends TrendsChart {
  constructor() {
    super(
      'Flicker Pinst (3-Ph Summary)',
      [['Flicker Pinst Avg'], ['Flicker Pinst Min', 'Flicker Pinst Max']],
      [
        new SeriesOptions('arearange', 'rgba(153, 198, 253, .3)', 'Min/Max', ['c_1611_min_none', 'c_1611_max_none'], {
          valueDecimals: 2,
        }),
        new SeriesOptions('line', '#0050BF', 'Avg', ['c_1611_avg_none'], {
          valueDecimals: 2,
        }),
      ]
    );

    // super.setYMinMax(0, 4);
  }
}

class FlickerPst extends TrendsChart {
  constructor() {
    super(
      'Flicker PST',
      [['Flicker PST Avg'], ['Flicker PST Min', 'Flicker PST Max']],
      [
        new SeriesOptions('arearange', 'rgba(153, 198, 253, .3)', 'Min/Max', ['c_1612_min_none', 'c_1612_max_none'], {
          valueDecimals: 2,
        }),
        new SeriesOptions('line', '#0050BF', 'Avg', ['c_1612_avg_none'], {
          valueDecimals: 2,
        }),
      ]
    );

    // super.setYMinMax(0, 4);
  }
}

class FlickerPlt extends TrendsChart {
  constructor() {
    super(
      'Flicker Plt (3-Ph summary)',
      [['Flicker Plt Avg'], ['Flicker Plt Min', 'Flicker Plt Max']],
      [
        new SeriesOptions('arearange', 'rgba(153, 198, 253, .3)', 'Min/Max', ['c_1613_min_none', 'c_1613_max_none'], {
          valueDecimals: 2,
        }),
        new SeriesOptions('line', '#0050BF', 'Avg', ['c_1613_avg_none'], {
          valueDecimals: 2,
        }),
      ]
    );

    // super.setYMinMax(0, 2);
  }
}

class NegativeVoltage extends TrendsChart {
  constructor() {
    super(
      'Voltage Unbalance (Negative Sequence)',
      [['IEC Negative Sequence V Avg(%)'], ['IEC Negative Sequence V Min(%)', 'IEC Negative Sequence V Max(%)']],
      [
        new SeriesOptions('arearange', 'rgba(153, 198, 253, .3)', 'Min/Max', ['c_287_min_%', 'c_287_max_%'], {
          valueSuffix: ' %',
          valueDecimals: 2,
        }),
        new SeriesOptions('line', '#0050BF', 'Avg', ['c_287_avg_%'], {
          valueSuffix: ' %',
          valueDecimals: 2,
        }),
      ]
    );

    super.setYMinMax(0, 3);
  }
}

class NegativeCurrent extends TrendsChart {
  constructor() {
    super(
      'Current Unbalance (Negative Sequence)',
      [['IEC Negative Sequence A Avg'], ['IEC Negative Sequence A Min', 'IEC Negative Sequence A Max']],
      [
        new SeriesOptions('arearange', 'rgba(153, 198, 253, .3)', 'Min/Max', ['c_288_min_%', 'c_288_max_%'], {
          valueSuffix: ' %',
          valueDecimals: 2,
        }),
        new SeriesOptions('line', '#0050BF', 'Avg', ['c_288_avg_%'], {
          valueSuffix: ' %',
          valueDecimals: 2,
        }),
      ]
    );

    super.setYMinMax(0, 50);
  }
}

class TotalHarmonicDistortion extends TrendsChart {
  constructor() {
    super(
      'Total Harmonic Distortion THD',
      [['THD-V Avg(%)'], ['THD-V Min(%)', 'THD-V Max(%)']],
      [
        new SeriesOptions('arearange', 'rgba(153, 198, 253, .3)', 'Min/Max', ['c_1609_min_%', 'c_1609_max_%'], {
          valueSuffix: ' %',
          valueDecimals: 2,
        }),
        new SeriesOptions('line', '#0050BF', 'Avg', ['c_1609_avg_%'], {
          valueSuffix: ' %',
          valueDecimals: 2,
        }),
      ]
    );

    super.setYMinMax(0, 10);
  }
}

class TotalDemandDistortion extends TrendsChart {
  constructor() {
    super(
      'Total Demand Distortion TDD',
      [['TDD-A Avg(%)'], ['TDD-A Min(%)', 'TDD-A Max(%)']],
      [
        new SeriesOptions('arearange', 'rgba(153, 198, 253, .3)', 'Min/Max', ['c_1610_min_%', 'c_1610_max_%'], {
          valueSuffix: ' %',
          valueDecimals: 2,
        }),
        new SeriesOptions('line', '#0050BF', 'Avg', ['c_1610_avg_%'], {
          valueSuffix: ' %',
          valueDecimals: 2,
        }),
      ]
    );

    super.setYMinMax(0, 50);
  }
}

// TODO return after endpoint is working to verify data structure
class VoltageOddHarmonics extends TrendsChart {
  constructor() {
    super(
      'Voltage Odd Harmonics<p style="width: 100%; text-align: center; margin: 0; padding: 0">(H3, H5, H7, H9, H11)</p>',
      [
        [
          'Voltage Harmonic H3(%)',
          'Voltage Harmonic H5(%)',
          'Voltage Harmonic H7(%)',
          'Voltage Harmonic H9(%)',
          'Voltage Harmonic H11(%)',
        ],
      ],
      [
        new SeriesOptions(
          'line',
          '#00E290',
          'H3',
          ['pc_1003_rms_%'],
          { valueSuffix: ' %', valueDecimals: 1 },
          'tenminute'
        ),
        new SeriesOptions(
          'line',
          '#0050BF',
          'H5',
          ['pc_1005_rms_%'],
          { valueSuffix: ' %', valueDecimals: 1 },
          'tenminute'
        ),
        new SeriesOptions(
          'line',
          '#FFBE08',
          'H7',
          ['pc_1007_rms_%'],
          { valueSuffix: ' %', valueDecimals: 1 },
          'tenminute'
        ),
        new SeriesOptions(
          'line',
          '#0087FF',
          'H9',
          ['pc_1009_rms_%'],
          { valueSuffix: ' %', valueDecimals: 1 },
          'tenminute'
        ),
        new SeriesOptions(
          'line',
          '#202F44',
          'H11',
          ['pc_1011_rms_%'],
          { valueSuffix: ' %', valueDecimals: 1 },
          'tenminute'
        ),
      ]
    );
    super.setYMinMax(0, undefined);
    super.yAxisOptions[0].title.useHTML = true;
    super.yAxisOptions[0].title.textAlign = 'center';
  }
}

class CurrentOddHarmonics extends TrendsChart {
  constructor() {
    super(
      'Current Odd Harmonics<p style="width: 100%; text-align: center; margin: 0; padding: 0">(H3, H5, H7, H9, H11)</p>',
      [
        [
          'Current Harmonic H3(A)',
          'Current Harmonic H5(A)',
          'Current Harmonic H7(A)',
          'Current Harmonic H9(A)',
          'Current Harmonic H11(A)',
        ],
      ],
      [
        new SeriesOptions(
          'line',
          '#00E290',
          'H3',
          ['pc_2003_rms_a'],
          { valueSuffix: ' Amps', valueDecimals: 1 },
          'tenminute'
        ),
        new SeriesOptions(
          'line',
          '#0050BF',
          'H5',
          ['pc_2005_rms_a'],
          { valueSuffix: ' Amps', valueDecimals: 1 },
          'tenminute'
        ),
        new SeriesOptions(
          'line',
          '#FFBE08',
          'H7',
          ['pc_2007_rms_a'],
          { valueSuffix: ' Amps', valueDecimals: 1 },
          'tenminute'
        ),
        new SeriesOptions(
          'line',
          '#0087FF',
          'H9',
          ['pc_2009_rms_a'],
          { valueSuffix: ' Amps', valueDecimals: 1 },
          'tenminute'
        ),
        new SeriesOptions(
          'line',
          '#202F44',
          'H11',
          ['pc_2011_rms_a'],
          { valueSuffix: ' Amps', valueDecimals: 1 },
          'tenminute'
        ),
      ]
    );
    super.setYMinMax(0, undefined);
    super.yAxisOptions[0].title.useHTML = true;
    super.yAxisOptions[0].title.textAlign = 'center';
  }
}

class VoltageAdvancedHarmonics extends AdvancedChart {
  constructor() {
    super(
      'Voltage Harmonics',
      [['H[x]']],
      [
        new SeriesOptions(
          'line',
          '#FF515B',
          'H[x]',
          ['c_511_rms_%'],
          { valueSuffix: ' %', valueDecimals: 1 },
          'tenminute'
        ),
        new SeriesOptions(
          'line',
          '#FFBE08',
          'H[x]',
          ['c_561_rms_%'],
          { valueSuffix: ' %', valueDecimals: 1 },
          'tenminute'
        ),
        new SeriesOptions(
          'line',
          '#0087FF',
          'H[x]',
          ['c_611_rms_%'],
          { valueSuffix: ' %', valueDecimals: 1 },
          'tenminute'
        ),
      ],
      'H2'
    );
    super.setYMinMax(0, undefined);
  }
}

class VoltageAdvancedInterharmonics extends AdvancedChart {
  constructor() {
    super(
      'Voltage Interharmonics',
      [['H[x]']],
      [
        new SeriesOptions(
          'line',
          '#FF515B',
          'H[x]',
          ['c_811_rms_%'],
          { valueSuffix: ' %', valueDecimals: 1 },
          'tenminute'
        ),
        new SeriesOptions(
          'line',
          '#FFBE08',
          'H[x]',
          ['c_861_rms_%'],
          { valueSuffix: ' %', valueDecimals: 1 },
          'tenminute'
        ),
        new SeriesOptions(
          'line',
          '#0087FF',
          'H[x]',
          ['c_911_rms_%'],
          { valueSuffix: ' %', valueDecimals: 1 },
          'tenminute'
        ),
      ],
      'IH1'
    );
    super.setYMinMax(0, undefined);
  }
}

class CurrentAdvancedHarmonics extends AdvancedChart {
  constructor() {
    super(
      'Current Harmonics',
      [['H[x]']],
      [
        new SeriesOptions(
          'line',
          '#FF515B',
          'H1[x]',
          ['c_661_rms_a'],
          { valueSuffix: ' A', valueDecimals: 1 },
          'tenminute'
        ),
        new SeriesOptions(
          'line',
          '#FFBE08',
          'H2[x]',
          ['c_713_rms_a'],
          { valueSuffix: ' A', valueDecimals: 1 },
          'tenminute'
        ),
        new SeriesOptions(
          'line',
          '#0087FF',
          'H33[x]',
          ['c_763_rms_a'],
          { valueSuffix: ' A', valueDecimals: 1 },
          'tenminute'
        ),
      ],
      'H2'
    );
    super.setYMinMax(0, undefined);
  }
}

class CurrentAdvancedInterharmonics extends AdvancedChart {
  constructor() {
    super(
      'Current Interharmonics',
      [['H[x]']],
      [
        new SeriesOptions(
          'line',
          '#FF515B',
          'H[x]',
          ['c_961_rms_a'],
          { valueSuffix: ' A', valueDecimals: 1 },
          'tenminute'
        ),
        new SeriesOptions(
          'line',
          '#FFBE08',
          'H[x]',
          ['c_1011_rms_a'],
          { valueSuffix: ' A', valueDecimals: 1 },
          'tenminute'
        ),
        new SeriesOptions(
          'line',
          '#0087FF',
          'H[x]',
          ['c_1061_rms_a'],
          { valueSuffix: ' A', valueDecimals: 1 },
          'tenminute'
        ),
      ],
      'H1'
    );
    super.setYMinMax(0, undefined);
  }
}

class GroundCurrent extends TrendsChart {
  constructor() {
    super(
      'Ground Current IE (A)',
      [
        ['E Current RMS 1/2 (1-cyc) Avg(Amps)'],
        ['E Current RMS 1/2 (1-cyc) Min(Amps)', 'E Current RMS 1/2 (1-cyc) Max(Amps)'],
      ],
      [
        new SeriesOptions('arearange', 'rgba(0, 226, 144, .3)', 'Min/Max', ['c_20_min_a', 'c_20_max_a'], {
          valueSuffix: ' Amps',
          valueDecimals: 2,
        }),
        new SeriesOptions('line', '#0e9015', 'Avg', ['c_20_avg_a'], {
          valueSuffix: ' Amps',
          valueDecimals: 2,
        }),
      ]
    );
  }
}

class VoltageL1N extends TrendsChart {
  constructor() {
    super(
      'Voltage L1-N (V)',
      [['Voltage L1-N 3'], ['Voltage L1-N 1', 'Voltage L1-N 2']],
      [
        new SeriesOptions('arearange', 'rgba(255, 133, 139, .3)', 'Min/Max', ['c_4_min_v', 'c_4_max_v'], {
          valueSuffix: ' V',
          valueDecimals: 1,
        }),
        new SeriesOptions('line', '#FF515B', 'Avg', ['c_4_avg_v'], {
          valueSuffix: ' V',
          valueDecimals: 1,
        }),
      ]
    );
    super.yAxisOptions[0].softMin = undefined;
    super.yAxisOptions[0].softMax = undefined;
  }
}

class VoltageL2N extends TrendsChart {
  constructor() {
    super(
      'Voltage L2-N (V)',
      [['Voltage L2-N 3'], ['Voltage L2-N 1', 'Voltage L2-N 2']],
      [
        new SeriesOptions('arearange', 'rgba(255, 220, 122, .3)', 'Min/Max', ['c_5_min_v', 'c_5_max_v'], {
          valueSuffix: ' V',
          valueDecimals: 1,
        }),
        new SeriesOptions('line', '#FFBE08', 'Avg', ['c_5_avg_v'], {
          valueSuffix: ' V',
          valueDecimals: 1,
        }),
      ]
    );
    super.yAxisOptions[0].softMin = undefined;
    super.yAxisOptions[0].softMax = undefined;
  }
}

class VoltageL3N extends TrendsChart {
  constructor() {
    super(
      'Voltage L3-N (V)',
      [['Voltage L3-N 3'], ['Voltage L3-N 1', 'Voltage L3-N 2']],
      [
        new SeriesOptions('arearange', 'rgba(153, 198, 253, .3)', 'Min/Max', ['c_6_min_v', 'c_6_max_v'], {
          valueSuffix: ' V',
          valueDecimals: 1,
        }),
        new SeriesOptions('line', '#0050BF', 'Avg', ['c_6_avg_v'], {
          valueSuffix: ' V',
          valueDecimals: 1,
        }),
      ]
    );
    super.yAxisOptions[0].softMin = undefined;
    super.yAxisOptions[0].softMax = undefined;
  }
}

class VoltageL1L2 extends TrendsChart {
  constructor() {
    super(
      'Voltage L1-L2 (V)',
      [['Voltage L1-L2 3'], ['Voltage L1-L2 1', 'Voltage L1-L2 2']],
      [
        new SeriesOptions('arearange', 'rgba(255, 133, 139, .3)', 'Min/Max', ['c_7_min_v', 'c_7_max_v'], {
          valueSuffix: ' V',
          valueDecimals: 1,
        }),
        new SeriesOptions('line', '#FF515B', 'Avg', ['c_7_avg_v'], {
          valueSuffix: ' V',
          valueDecimals: 1,
        }),
      ]
    );
    super.yAxisOptions[0].softMin = undefined;
    super.yAxisOptions[0].softMax = undefined;
  }
}

class VoltageL2L3 extends TrendsChart {
  constructor() {
    super(
      'Voltage L2-L3 (V)',
      [['Voltage L2-L3 3'], ['Voltage L2-L3 1', 'Voltage L2-L3 2']],
      [
        new SeriesOptions('arearange', 'rgba(255, 220, 122, .3)', 'Min/Max', ['c_8_min_v', 'c_8_max_v'], {
          valueSuffix: ' V',
          valueDecimals: 1,
        }),
        new SeriesOptions('line', '#FFBE08', 'Avg', ['c_8_avg_v'], {
          valueSuffix: ' V',
          valueDecimals: 1,
        }),
      ]
    );
    super.yAxisOptions[0].softMin = undefined;
    super.yAxisOptions[0].softMax = undefined;
  }
}

class VoltageL3L1 extends TrendsChart {
  constructor() {
    super(
      'Voltage L3-L1 (V)',
      [['Voltage L3-L1 3'], ['Voltage L3-L1 1', 'Voltage L3-L1 2']],
      [
        new SeriesOptions('arearange', 'rgba(153, 198, 253, .3)', 'Min/Max', ['c_9_min_v', 'c_9_max_v'], {
          valueSuffix: ' V',
          valueDecimals: 1,
        }),
        new SeriesOptions('line', '#0050BF', 'Avg', ['c_9_avg_v'], {
          valueSuffix: ' V',
          valueDecimals: 1,
        }),
      ]
    );
    super.yAxisOptions[0].softMin = undefined;
    super.yAxisOptions[0].softMax = undefined;
  }
}

class CurrentL1 extends TrendsChart {
  constructor() {
    super(
      'Current L1 (A)',
      [['Current L1 Avg'], ['Current L1 Min', 'Current L1 Max']],
      [
        new SeriesOptions('arearange', 'rgba(255, 133, 139, .3)', 'Min/Max', ['c_16_min_a', 'c_16_max_a'], {
          valueSuffix: ' A',
          valueDecimals: 1,
        }),
        new SeriesOptions('line', '#FF515B', 'Avg', ['c_16_avg_a'], {
          valueSuffix: ' A',
          valueDecimals: 1,
        }),
      ]
    );
  }
}

class CurrentL2 extends TrendsChart {
  constructor() {
    super(
      'Current L2 (A)',
      [['Current L2 Avg'], ['Current L2 Min', 'Current L2 Max']],
      [
        new SeriesOptions('arearange', 'rgba(255, 220, 122, .3)', 'Min/Max', ['c_17_min_a', 'c_17_max_a'], {
          valueSuffix: ' A',
          valueDecimals: 1,
        }),
        new SeriesOptions('line', '#FFBE08', 'Avg', ['c_17_avg_a'], {
          valueSuffix: ' A',
          valueDecimals: 1,
        }),
      ]
    );
  }
}

class CurrentL3 extends TrendsChart {
  constructor() {
    super(
      'Current L3 (A)',
      [['Current L3 Avg'], ['Current L3 Min', 'Current L3 Max']],
      [
        new SeriesOptions('arearange', 'rgba(153, 198, 253, .3)', 'Min/Max', ['c_18_min_a', 'c_18_max_a'], {
          valueSuffix: ' A',
          valueDecimals: 1,
        }),
        new SeriesOptions('line', '#0050BF', 'Avg', ['c_18_avg_a'], {
          valueSuffix: ' A',
          valueDecimals: 1,
        }),
      ]
    );
  }
}

class NeutralCurrent extends TrendsChart {
  constructor() {
    super(
      'Neutral Current IN(A)',
      [
        ['N Current RMS 1/2 (1-cyc) Avg(Amps)'],
        ['N Current RMS 1/2 (1-cyc) Min(Amps)', 'N Current RMS 1/2 (1-cyc) Max(Amps)'],
      ],
      [
        new SeriesOptions('arearange', 'rgba(228, 235, 239, .3)', 'Min/Max', ['c_19_min_a', 'c_19_max_a'], {
          valueSuffix: ' Amps',
          valueDecimals: 2,
        }),
        new SeriesOptions('line', '#536A84', 'Avg', ['c_19_avg_a'], {
          valueSuffix: ' Amps',
          valueDecimals: 2,
        }),
      ]
    );
  }
}

class Frequency extends TrendsChart {
  constructor() {
    super(
      'Frequency (Hz)',
      [['Frequency Avg'], ['Frequency Min', 'Frequency Max']],
      [
        new SeriesOptions('arearange', 'rgba(153, 198, 253, .3)', 'Min/Max', ['c_21_min_hz', 'c_21_max_hz'], {
          valueSuffix: ' Hz',
          valueDecimals: 3,
        }),
        new SeriesOptions('line', '#0050BF', 'Avg', ['c_21_avg_hz'], {
          valueSuffix: ' Hz',
          valueDecimals: 3,
        }),
      ]
    );
    super.yAxisOptions[0].softMin = undefined;
    super.yAxisOptions[0].softMax = undefined;
  }
}

class VoltageNE extends TrendsChart {
  constructor() {
    super(
      'Voltage N-E (V)',
      [['Voltage N-E Avg'], ['Voltage N-E Min', 'Voltage N-E Max']],
      [
        new SeriesOptions('arearange', 'rgba(228, 235, 239, .3)', 'Min/Max', ['c_3_min_v', 'c_3_max_v'], {
          valueSuffix: ' V',
          valueDecimals: 2,
        }),
        new SeriesOptions('line', '#536A84', 'Avg', ['c_3_avg_v'], {
          valueSuffix: ' V',
          valueDecimals: 2,
        }),
      ]
    );
  }
}

@Injectable()
export class ChartDefinitions {
  constructor(private store: Store<fromUser.State>) {}

  public getDynamic(channel: Channel) {
    return new Dynamic(channel);
  }

  getDynamicTrendAlarm() {
    return new DynamicTrendsAlarm();
  }

  dashboardCustom(channel: any) {
    return new DashboardCustom(channel);
  }

  public get dashboard() {
    return new Dashboard();
  }

  get dashboard1Phase() {
    return new Dashboard1Phase();
  }

  public get activeEnergy() {
    return new ActiveEnergy();
  }

  public get reactiveEnergy() {
    return new ReactiveEnergy();
  }

  public get activePower() {
    return new ActivePower();
  }

  public get reactivePower() {
    return new ReactivePower();
  }

  public get powerFactor() {
    return new PowerFactor();
  }

  public get totalReactivePower() {
    return new TotalReactivePower();
  }

  public get apparentPower() {
    return new ApparentPower();
  }

  public get pqEvents() {
    return new PQEvents(this.store);
  }

  public get totalPowerFactor() {
    return new TotalPowerFactor();
  }

  public get totalActivePower() {
    return new TotalActivePower();
  }

  public get voltageLNRMSMagnitude() {
    return new VoltageLNRMSMagnitude();
  }

  public get voltageLLRMSMagnitude() {
    return new VoltageLLRMSMagnitude();
  }

  public get currentMagnitudeAndVariations() {
    return new CurrentMagnitudeAndVariations();
  }

  public get flickerPinst() {
    return new FlickerPinst();
  }

  public get flickerPst() {
    return new FlickerPst();
  }

  public get negativeVoltage() {
    return new NegativeVoltage();
  }

  public get negativeCurrent() {
    return new NegativeCurrent();
  }

  public get totalHarmonicDistortion() {
    return new TotalHarmonicDistortion();
  }

  public get totalDemandDistortion() {
    return new TotalDemandDistortion();
  }

  public get voltageOddHarmonics() {
    return new VoltageOddHarmonics();
  }

  public get currentOddHarmonics() {
    return new CurrentOddHarmonics();
  }

  public get voltageAdvancedHarmonics() {
    return new VoltageAdvancedHarmonics();
  }

  public get voltageAdvancedInterharmonics() {
    return new VoltageAdvancedInterharmonics();
  }

  public get currentAdvancedHarmonics() {
    return new CurrentAdvancedHarmonics();
  }

  public get currentAdvancedInterharmonics() {
    return new CurrentAdvancedInterharmonics();
  }

  public get groundCurrent() {
    return new GroundCurrent();
  }

  public get neutralCurrent() {
    return new NeutralCurrent();
  }

  public get voltageL1N() {
    return new VoltageL1N();
  }

  public get voltageL2N() {
    return new VoltageL2N();
  }

  public get voltageL3N() {
    return new VoltageL3N();
  }

  public get voltageL1L2() {
    return new VoltageL1L2();
  }

  public get voltageL2L3() {
    return new VoltageL2L3();
  }

  public get voltageL3L1() {
    return new VoltageL3L1();
  }

  public get voltageNE() {
    return new VoltageNE();
  }

  public get flickerPlt() {
    return new FlickerPlt();
  }

  public get currentL1() {
    return new CurrentL1();
  }

  public get currentL2() {
    return new CurrentL2();
  }

  public get currentL3() {
    return new CurrentL3();
  }

  public get frequency() {
    return new Frequency();
  }

  public get voltageZeroSequenceUnbalance() {
    return new VoltageZeroSequenceUnbalance();
  }

  public get currentZeroSequenceUnbalance() {
    return new CurrentZeroSequenceUnbalance();
  }
}
