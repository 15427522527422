import { ITrendsChart, PowersideYAxisOptions } from 'src/app/_shared/classes/chart.interface';
import { AlarmEventNoteResponse } from 'src/app/_shared/interface/alarm/alarm-event-note-request.interface';
import * as moment from 'moment/moment';
import { DriveState } from 'src/app/_shared/enum/drive-state.enum';
import { SeriesOptions } from 'src/app/_shared/services/chart-definitions/options/series-options';

export class TrendsChart implements ITrendsChart {
  private mYAxis: number;
  private mName: string;
  private mCsvHeaders: string[][];
  private mSeriesArray: SeriesOptions[];
  private mYAxisOptions: PowersideYAxisOptions[];
  private mSpacer = 30;
  public id: string;

  public set name(name: string) {
    this.mName = name;
  }

  public get name(): string {
    return this.mName;
  }

  public set csvHeaders(csvHeaders: string[][]) {
    this.mCsvHeaders = csvHeaders;
  }

  public get csvHeaders(): string[][] {
    return this.mCsvHeaders;
  }

  public get oneMinuteChannelIds(): string[] {
    return this.seriesArray.reduce((accumulator, series) => {
      if (series.table === 'oneminute') {
        return accumulator.concat(series.channelIds);
      } else return accumulator;
    }, []);
  }

  public get tenMinuteChannelIds(): string[] {
    return this.seriesArray.reduce((accumulator, series) => {
      if (series.table === 'tenminute') {
        return accumulator.concat(series.channelIds);
      } else return accumulator;
    }, []);
  }

  public set seriesArray(series: SeriesOptions[]) {
    this.mSeriesArray = series;
  }

  public get seriesArray(): SeriesOptions[] {
    return this.mSeriesArray;
  }

  public set yAxis(newIndex: number) {
    this.mYAxis = newIndex;
    this.seriesArray.forEach((series) => (series.yAxis = newIndex));
  }

  public get yAxis(): number {
    return this.mYAxis;
  }

  public get yAxisOptions(): PowersideYAxisOptions[] {
    return this.mYAxisOptions;
  }

  public set yAxisOptions(newOptions: PowersideYAxisOptions[]) {
    this.mYAxisOptions = newOptions;
  }

  public set spacer(gap: number) {
    this.mSpacer = gap;
  }

  public get spacer(): number {
    return this.mSpacer;
  }

  public clearSeriesData(): void {
    this.seriesArray.forEach((series) => (series.data = []));
  }

  public setSeriesData(
    isInsite: boolean,
    dataBySeries: any,
    eventsDataArray: AlarmEventNoteResponse[],
    alarmsDataArray: AlarmEventNoteResponse[],
    granularity: moment.DurationInputArg2,
    interval: number
  ): Array<SeriesOptions> {
    this.seriesArray.forEach((series) => {
      series.setData(dataBySeries);
      series.setDataGap(granularity, interval);
    });

    return this.seriesArray;
  }

  public setYMinMax(min: number, max: number): void {
    this.yAxisOptions.forEach((yAxis) => {
      yAxis.min = min;
      yAxis.max = max;
      yAxis.endOnTick = false;
      yAxis.startOnTick = false;
    });
  }

  public setYMin(min: number): void {
    this.yAxisOptions.forEach((yAxis) => {
      yAxis.min = min;
      yAxis.startOnTick = false;
    });
  }

  public setYMax(max: number): void {
    this.yAxisOptions.forEach((yAxis) => {
      yAxis.max = max;
      yAxis.endOnTick = false;
    });
  }

  constructor(name: string, csvHeaders: string[][], series: SeriesOptions[]) {
    this.name = name;
    this.csvHeaders = csvHeaders;
    this.seriesArray = series;

    this.id = name;

    this.yAxisOptions = [
      {
        labels: {
          align: 'right',
          x: -3,
          formatter:
            name.toLowerCase() === 'drive state'
              ? function (): string | undefined {
                  return DriveState[this.pos];
                }
              : function (): string {
                  if (Math.abs(Number(this.value)) > 1000000) {
                    return parseFloat(this.value.toString()) / 1000000 + 'M';
                  } else if (Math.abs(Number(this.value)) > 1000) {
                    return parseFloat(this.value.toString()) / 1000 + 'k';
                  } else {
                    return this.value.toString();
                  }
                },
        },
        title: {
          text: this.name,
        },
        height: 180,
        lineWidth: 1,
        resize: {
          enabled: true,
        },
        offset: 0,
        // softMin: 0,
        //softMax: 0.001,
        showLastLabel: true,
      },
    ];
  }
}
