import { IEventsChart, PowersideYAxisOptions } from 'src/app/_shared/classes/chart.interface';
import { AlarmEventNoteResponse } from 'src/app/_shared/interface/alarm/alarm-event-note-request.interface';
import * as moment from 'moment/moment';
import { Store } from '@ngrx/store';
import * as fromUser from 'src/app/_store/_reducers';
import { take } from 'rxjs/operators';
import { EventSeriesOptions } from 'src/app/_shared/services/chart-definitions/options/event-series-options';
import { EventSeriesDurationLineOptions } from 'src/app/_shared/services/chart-definitions/options/event-series-duration-line-options';

export class PQEvents implements IEventsChart {
  channelIds = [];
  name = 'Events';
  mYAxis;
  yAxisOptions: PowersideYAxisOptions[];
  private isQubeScan: boolean;
  private isDriveScan: boolean;
  private mSeriesArray: EventSeriesOptions[] = [];
  private mSpacer = 30;

  constructor(private store: Store<fromUser.State>) {
    this.store
      .select(fromUser.getMp)
      .pipe(take(1))
      .subscribe((mp) => {
        this.isQubeScan = mp.measurementPointTypeId === 1;
        this.isDriveScan = mp.measurementPointTypeId === 3;
        // Initialize series
        this.initSeries();
      });
  }

  initSeries(): void {
    // Define event to display based on pQube model
    let eventToDisplay = [];
    if (this.isQubeScan || this.isDriveScan) {
      eventToDisplay = [
        'voltageSag',
        'voltageSwell',
        'interruption',
        'highFrequencyImpulse',
        'Others',
        'restart',
        'rvc',
        'waveShapeChange',
        'snapshot',
        'note',
      ];
    } else {
      eventToDisplay = ['voltageSag', 'voltageSwell', 'interruption', 'highFrequencyImpulse'];
    }
    // Initialize series options
    eventToDisplay.forEach((eventType) => {
      const eventProperties = this.getEventProperties(eventType);
      this.mSeriesArray.push(
        new EventSeriesOptions(
          eventProperties.name,
          eventProperties.type,
          eventProperties.symbol,
          eventProperties.yValue,
          eventProperties.color
        )
      );
    });

    this.yAxisOptions = [
      {
        visible: true,
        tickPositions: [0, 1, 2, 3, 4, 5, 6],
        labels: {
          enabled: false,
        },
        min: 0,
        max: 2,
        height: 100,
        lineWidth: 1,
        resize: {
          enabled: false,
        },
        offset: 0,
        plotBands: [
          {
            acrossPanes: true,
            color: '#F0F5F7',
            from: 0,
            to: 6,
          },
        ],
      },
    ];
  }

  public set yAxis(newYAxis: number) {
    this.mYAxis = newYAxis;
    this.seriesArray.forEach((series) => (series.yAxis = newYAxis));
  }

  public get yAxis(): number {
    return this.mYAxis;
  }

  public get seriesArray(): EventSeriesOptions[] | EventSeriesDurationLineOptions[] {
    return this.mSeriesArray;
  }

  public get spacer(): number {
    return this.mSpacer;
  }

  public set spacer(gap: number) {
    this.mSpacer = gap;
  }

  public clearSeriesData(): void {
    // Remove Duration line series
    this.mSeriesArray = this.mSeriesArray.filter((series) => !(series instanceof EventSeriesDurationLineOptions));
    // Clean Event series data
    this.mSeriesArray.forEach((series) => (series.data = []));
  }

  private getEventProperties(type: string): {
    name: string;
    type: string;
    imageURL: string;
    yValue: number;
    color: string;
    symbol: string;
  } {
    const eventProps = {
      name: null,
      type: null,
      imageURL: null,
      yValue: null,
      color: null,
      symbol: null,
    };
    switch (type) {
      case 'voltageSag':
        eventProps.name = this.isQubeScan ? 'Sag' : 'Major Sag';
        eventProps.type = 'sags';
        eventProps.yValue = this.isQubeScan || this.isDriveScan ? 4 : 4;
        eventProps.color = '#107CE1';
        eventProps.symbol = 'triangle-down';
        break;
      case 'voltageSwell':
        eventProps.name = 'Voltage Swell';
        eventProps.type = 'swells';
        eventProps.yValue = this.isQubeScan || this.isDriveScan ? 5 : 3;
        eventProps.color = '#000000';
        eventProps.symbol = `url(${location.protocol}//${location.host}/assets/images/legend/triangle.svg)`;
        break;
      case 'interruption':
        eventProps.name = 'Interruption';
        eventProps.type = 'interruptions';
        eventProps.yValue = this.isQubeScan || this.isDriveScan ? 1 : 1;
        eventProps.color = '#FF0000';
        eventProps.symbol = `url(${location.protocol}//${location.host}/assets/images/legend/square.svg)`;
        break;
      case 'highFrequencyImpulse':
        eventProps.name = 'High Frequency Impulse';
        eventProps.type = 'impulses';
        eventProps.yValue = this.isQubeScan || this.isDriveScan ? 5 : 2;
        eventProps.symbol = `url(${location.protocol}//${location.host}/assets/images/legend/hfImpulses.svg)`;
        break;
      case 'restart':
        eventProps.name = 'Restart';
        eventProps.type = 'restart';
        eventProps.yValue = 0;
        eventProps.symbol = `url(${location.protocol}//${location.host}/assets/images/legend/restart.svg)`;
        break;
      case 'Others':
        eventProps.name = 'Others';
        eventProps.type = 'other';
        eventProps.yValue = 2;
        eventProps.symbol = `url(${location.protocol}//${location.host}/assets/images/legend/other_events.svg)`;
        break;
      case 'rvc':
        eventProps.name = 'RVC';
        eventProps.type = 'rvc';
        eventProps.yValue = 3;
        eventProps.symbol = `url(${location.protocol}//${location.host}/assets/images/legend/rvc.svg)`;
        break;
      case 'waveShapeChange':
        eventProps.name = 'Waveshape';
        eventProps.type = 'waveshape';
        eventProps.yValue = 3;
        eventProps.symbol = `url(${location.protocol}//${location.host}/assets/images/legend/waveshape.svg)`;
        break;
      case 'snapshot':
        eventProps.name = 'Snapshot';
        eventProps.type = 'snapshot';
        eventProps.yValue = 2;
        eventProps.symbol = `url(${location.protocol}//${location.host}/assets/images/legend/circle.svg)`;
        break;
      case 'Note':
        eventProps.name = 'Note';
        eventProps.type = 'note';
        eventProps.yValue = 0;
        eventProps.symbol = `url(${location.protocol}//${location.host}/assets/images/calendar.svg)`;
        break;
      default:
        eventProps.name = 'Others';
        eventProps.type = 'other';
        eventProps.yValue = 2;
        eventProps.symbol = `url(${location.protocol}//${location.host}/assets/images/legend/other_events.svg)`;
    }
    return eventProps;
  }

  private makeEventDataHCCompatible(eventTimelineData): any[] {
    const eventsSeriesData = [];

    for (const eventType in eventTimelineData) {
      if (eventTimelineData.hasOwnProperty(eventType)) {
        eventsSeriesData.push({
          name: eventTimelineData[eventType].name,
          data: eventTimelineData[eventType].data,
          eventTypeId: eventTimelineData[eventType].description,
          findNearestPointBy: 'xy',
        });
      }
    }

    return eventsSeriesData;
  }

  private createNewEventSeries(event: AlarmEventNoteResponse): {
    data: any[];
    name: string;
    yValue: number;
    description: string | number;
  } {
    const { name, yValue } = this.getEventProperties(event.typeName);
    return {
      data: [],
      name,
      yValue,
      description: event.typeId,
    };
  }

  setSeriesData(
    isInsite: boolean,
    channelDataArray: any,
    eventsDataArray: AlarmEventNoteResponse[],
    alarmsDataArray: AlarmEventNoteResponse[],
    granularity: string,
    interval?: number,
    startDate?: any,
    endDate?: any
  ): any[] {
    const eventData = {};

    if (eventsDataArray.length === 0 || eventsDataArray === undefined) {
      return this.seriesArray;
    }
    const durationLineSeries = [];
    const longEventThresholdMs = 12000;
    for (const event of eventsDataArray) {
      if (
        event.typeName !== 'voltageSag' &&
        event.typeName !== 'voltageSwell' &&
        event.typeName !== 'interruption' &&
        event.typeName !== 'highFrequencyImpulse' &&
        event.typeName !== 'restart' &&
        event.typeName !== 'waveShapeChange' &&
        event.typeName !== 'rvc' &&
        event.typeName !== 'snapshot' &&
        event.typeName !== 'Note'
      ) {
        if (!eventData['Others']) {
          eventData['Others'] = this.createNewEventSeries(event);
        }
        eventData['Others'].data.push([
          moment(event.triggeredWhen).utc(false).valueOf(),
          eventData['Others'].yValue,
          event.defaultDisplayName,
        ]);
      } else {
        if (!eventData[event.typeName]) {
          eventData[event.typeName] = this.createNewEventSeries(event);
        }

        const eventStartTime = moment(event.triggeredWhen);
        const eventEndTime = moment(event.triggeredWhen).add(event.duration, 'millisecond');
        const eventEndTimeAdjusted = eventEndTime.isAfter(endDate) ? endDate.add(1, 'millisecond') : eventEndTime;
        const eventProperties = this.getEventProperties(event.typeName);

        if (['voltageSwell', 'voltageSag', 'interruption'].includes(event.typeName)) {
          eventData[event.typeName].data.push({
            x: eventStartTime.utc(false).valueOf(),
            y: eventProperties.yValue,
            eventId: event.id,
          });

          // Add end marker and duration line
          if (event.duration >= longEventThresholdMs) {
            eventData[event.typeName].data.push({
              x: eventEndTimeAdjusted.utc(false).valueOf(),
              y: eventProperties.yValue,
              eventId: event.id, // Event id is used to load the data for snackbar popup
            });
            // Add duration line
            durationLineSeries.push(
              new EventSeriesDurationLineOptions(
                event,
                eventProperties.name,
                eventProperties.type,
                eventStartTime.utc(false).valueOf() + 1,
                eventEndTimeAdjusted.utc(false).valueOf() - 1,
                eventProperties.yValue,
                { color: eventProperties.color, yAxis: this.yAxis }
              )
            );
          }
        } else {
          eventData[event.typeName].data.push([
            moment(event.triggeredWhen).utc(false).valueOf(),
            eventProperties.yValue,
          ]);
        }
      }
    }

    const newEventsDataArray = this.makeEventDataHCCompatible(eventData);
    this.seriesArray.forEach((series: EventSeriesOptions | EventSeriesDurationLineOptions) => {
      const matchedSeries = newEventsDataArray.find((newEvents) => newEvents.name === series.name);

      if (matchedSeries) {
        series.data.unshift(...matchedSeries.data);
        series.eventTypeId = matchedSeries.eventTypeId;
      }
    });

    // Add connection lines to the graph
    durationLineSeries.forEach((opt) => {
      this.seriesArray.push(opt);
    });

    return this.seriesArray;
  }
}
