import { IEventSeriesOptions } from 'src/app/_shared/classes/chart.interface';

import { AlarmEventNoteResponse } from 'src/app/_shared/interface/alarm/alarm-event-note-request.interface';
import {
  convertMomentToChartFormat,
  convertMomentToChartFormatGeneric,
} from 'src/app/_shared/helpers/chart-moment-format';
import * as moment from 'moment';

export class EventSeriesDurationLineOptions implements IEventSeriesOptions {
  id: string;
  type = 'line';
  /**
   * Custom attribute to identify this series: EventSeriesDurationLineOptions
   */
  typeId = 'durationLine';
  /**
   * Event type
   */
  deviceEventType: string;
  /**
   * Event type id
   */
  eventTypeId: number;
  color;
  data = [];
  channelIds = [];
  yValue: number;
  allowPointSelect: false;
  stickyTracking = false;
  dataGrouping = {
    enabled: false,
  };

  tooltip = {
    split: false,
    xDateFormat: `${convertMomentToChartFormatGeneric(
      'LL'
    )}<br/><div style="width: 100%; text-align: center;">${convertMomentToChartFormat(
      moment().creationData().locale.longDateFormat('LTS').replace('ss', 'ss.SSS')
    )}</div><br/>`,
    headerFormat: '{point.key}',
    // eslint-disable-next-line space-before-function-paren
    pointFormatter: function (): string {
      return this.series.name;
    },
    findNearestPointBy: 'xy',
  };

  lineWidth: number;

  states = {
    hover: {
      enabled: true,
      halo: {
        opacity: 0.25,
        size: 10,
      },
    },
  };
  turboThreshold = 0;
  name: string;
  eventType: string;
  marker = {
    enabled: false,
    radius: 0,
    symbol: null,
    width: null,
    height: null,
  };
  yAxis: number;

  constructor(
    event: AlarmEventNoteResponse,
    name: string,
    eventType: string,
    startValue: number,
    endValue: number,
    yValue: number,
    options: { [key: string]: any } = {}
  ) {
    if (options?.color) {
      this.color = options.color;
    }
    if (options?.marker) {
      this.marker = { ...this.marker, ...options.marker };
    }

    if (options?.yAxis) {
      this.yAxis = options.yAxis;
    }
    this.yValue = yValue;

    this.name = name;
    this.eventType = eventType;
    this.deviceEventType = event.typeName;
    this.eventTypeId = event.typeId;

    // Set line start/end
    this.data.push({ x: startValue, y: this.yValue, eventId: event.id });
    this.data.push({ x: endValue, y: this.yValue, eventId: event.id });

    this.lineWidth = 2;
  }
}
