import { createAction, props } from '@ngrx/store';

import { AlarmEventNoteResponse } from 'src/app/_shared/interface/alarm/alarm-event-note-request.interface';
import { EventNature } from 'src/app/_shared/interface/events/event-nature.interface';
import { PaginatedModel } from 'src/app/_shared/interface/pagination.interface';

export const initCharts = createAction(
  '[Charts] Init',
  props<{
    payload: {
      startDate: string;
      scope: string;
      timezone: string;
      granularity: string;
      interval: number;
      commissionedWhen: string;
      usePreCommissionStart: boolean;
      forceUpdate?: boolean;
    };
  }>()
);

export const setScope = createAction(
  '[Charts] Set Scope',
  props<{
    payload: {
      scope: string;
      granularity?: string;
    };
  }>()
);

export const setDate = createAction(
  '[Charts] Set Date',
  props<{
    payload: {
      startDate: string;
    };
  }>()
);

export const setInterval = createAction('[Charts] Set Interval');

export const changeInterval = createAction('[Charts] Change Interval', props<{ payload: { direction: string } }>());

export const setIntervalSuccess = createAction(
  '[Charts] Set Interval Success',
  props<{ payload: { startDate: string; endDate: string } }>()
);

export const setYear = createAction('[Charts] Set Year', props<{ payload: { year: number; chartSetName: string } }>());

export const setMonth = createAction(
  '[Charts] Set Month',
  props<{ payload: { year: number; month: number; chartSetName: string } }>()
);

export const setWeekDay = createAction(
  '[Charts] Set Week/Day',
  props<{ payload: { year: number; month: number; day: number } }>()
);

export const setTitleSuccess = createAction('[Charts] Set Title Success', props<{ payload: { title: string } }>());

export const setPrecommissionStart = createAction(
  '[Charts] Set Precommissioning Start',
  props<{ payload: { usePreCommissionStart: boolean } }>()
);

export const setEvents = createAction(
  '[Charts] Set Events',
  props<{
    payload: {
      start: string;
      end: string;
      natures: Array<string>;
      chartName: string;
      isQubescan: boolean;
      isInsite: boolean;
      from: string;
    };
  }>()
);

export const setEventsSuccess = createAction(
  '[Charts] Set Events Success',
  props<{
    payload: {
      nature: EventNature;
      events: PaginatedModel<AlarmEventNoteResponse>;
      alarms: PaginatedModel<AlarmEventNoteResponse>;
    };
  }>()
);

export const toggleEvent = createAction(
  '[Charts] Toggle Event',
  props<{
    payload: {
      type: string;
      value: boolean;
    };
  }>()
);

export type ChartsActionsUnion = ReturnType<
  | typeof initCharts
  | typeof setScope
  | typeof setDate
  | typeof setIntervalSuccess
  | typeof changeInterval
  | typeof setYear
  | typeof setMonth
  | typeof setWeekDay
  | typeof setTitleSuccess
  | typeof setPrecommissionStart
  | typeof setEvents
  | typeof setEventsSuccess
  | typeof toggleEvent
>;
