import { Action } from '@ngrx/store';
import * as moment from 'moment';
import 'moment-timezone';

import { AlarmEventNoteResponse } from 'src/app/_shared/interface/alarm/alarm-event-note-request.interface';
import { EventNature } from 'src/app/_shared/interface/events/event-nature.interface';
import { PaginatedModel } from 'src/app/_shared/interface/pagination.interface';
import { ModuleUtil } from 'src/app/_shared/utils/module.util';
import { ChartsActions } from 'src/app/_store/_charts/actions';

export interface State {
  startDate: any;
  endDate: any;
  scope: any;
  granularity: string;
  interval: number;
  title: string;
  commissionedWhen: string;
  usePreCommissionStart: boolean;
  nature: EventNature;
  events: PaginatedModel<AlarmEventNoteResponse>;
  alarms: PaginatedModel<AlarmEventNoteResponse>;
  toggleEvent: { type: string; value: boolean };
}

const initialState: State = {
  startDate: null,
  endDate: null,
  scope: null,
  granularity: null,
  interval: null,
  title: null,
  commissionedWhen: null,
  usePreCommissionStart: null,
  nature: {
    alarm: null,
    note: null,
    event: {
      sags: null,
      swells: null,
      impulses: null,
      interruptions: null,
      other: null,
      waveshape: null,
      rvc: null,
      snapshot: null,
    },
    status: { restart: null },
  },
  events: null,
  alarms: null,
  toggleEvent: { type: null, value: null },
};

export function reducer(state: State = initialState, action: Action): State {
  const specificAction = action as ChartsActions.ChartsActionsUnion;
  switch (specificAction.type) {
    case ChartsActions.initCharts.type:
      if (JSON.stringify(state) === JSON.stringify(initialState) || specificAction.payload.forceUpdate) {
        let startDate, endDate, title;
        if (specificAction.payload.startDate) {
          startDate = moment(specificAction.payload.startDate, 'L')
            .tz(specificAction.payload.timezone)
            .startOf('days')
            .format('L');
          endDate = moment(specificAction.payload.startDate, 'L')
            .tz(specificAction.payload.timezone)
            .endOf('days')
            .format('L');
          title = moment(specificAction.payload.startDate, 'L').tz(specificAction.payload.timezone).format('dddd LL');
        } else {
          startDate = moment().tz(specificAction.payload.timezone).startOf('days').format('L');
          endDate = moment().tz(specificAction.payload.timezone).endOf('days').format('L');
          title = moment().tz(specificAction.payload.timezone).format('dddd LL');
        }
        return {
          ...state,
          startDate: startDate,
          endDate: endDate,
          scope: 'days',
          granularity: specificAction.payload.granularity,
          interval: specificAction.payload.interval,
          title: title,
          commissionedWhen: specificAction.payload.commissionedWhen,
          usePreCommissionStart: specificAction.payload.usePreCommissionStart,
          nature: {
            alarm: null,
            note: null,
            event: {
              sags: null,
              swells: null,
              impulses: null,
              interruptions: null,
              other: null,
              waveshape: null,
              rvc: null,
              snapshot: null,
            },
            status: { restart: null },
          },
          events: null,
        };
      } else {
        return state;
      }
    case ChartsActions.setScope.type:
      let granularity: string;
      let newInterval: number;

      const newGranularity = ModuleUtil.calculateGranularityPeriod(specificAction.payload.scope);
      granularity = newGranularity.granularity;
      newInterval = newGranularity.interval;
      return {
        ...state,
        scope: specificAction.payload.scope,
        granularity: granularity,
        interval: newInterval,
      };
    case ChartsActions.setDate.type:
      return {
        ...state,
        startDate: specificAction.payload.startDate,
      };
    case ChartsActions.setIntervalSuccess.type:
      return {
        ...state,
        startDate: specificAction.payload.startDate,
        endDate: specificAction.payload.endDate,
      };
    case ChartsActions.setTitleSuccess.type:
      return {
        ...state,
        title: specificAction.payload.title,
      };
    case ChartsActions.setPrecommissionStart.type:
      return {
        ...state,
        usePreCommissionStart: specificAction.payload.usePreCommissionStart,
      };
    case ChartsActions.setEventsSuccess.type:
      return {
        ...state,
        nature: {
          alarm: specificAction.payload.nature.alarm,
          note: specificAction.payload.nature.note,
          event: {
            sags: specificAction.payload.nature.event.sags,
            swells: specificAction.payload.nature.event.swells,
            impulses: specificAction.payload.nature.event.impulses,
            interruptions: specificAction.payload.nature.event.interruptions,
            other: specificAction.payload.nature.event.other,
            waveshape: specificAction.payload.nature.event.waveshape,
            rvc: specificAction.payload.nature.event.rvc,
            snapshot: specificAction.payload.nature.event.snapshot,
          },
          status: { restart: specificAction.payload.nature.status.restart },
        },
        events: specificAction.payload.events,
        alarms: specificAction.payload.alarms,
      };
    case ChartsActions.toggleEvent.type:
      return {
        ...state,
        toggleEvent: { type: specificAction.payload.type, value: specificAction.payload.value },
      };
    default:
      return state;
  }
}

export const getCharts = (state: State): State => state;
export const getStartDate = (state: State): State['startDate'] => state.startDate;
export const getEndDate = (state: State): State['endDate'] => state.endDate;
export const getScope = (state: State): State['scope'] => state.scope;
export const getTitle = (state: State): string => state.title;
export const getCommissionedWhen = (state: State): State['commissionedWhen'] => state.commissionedWhen;
export const getUsePreCommissionStart = (state: State): State['usePreCommissionStart'] => state.usePreCommissionStart;
export const getNature = (state: State): State['nature'] => state.nature;
export const getEvents = (state: State): State['events'] => state.events;
