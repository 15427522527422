import { ISeriesOptions } from 'src/app/_shared/classes/chart.interface';
import * as Highcharts from 'highcharts/highstock';
import * as moment from 'moment/moment';

export class SeriesOptions implements ISeriesOptions {
  public tooltip: Highcharts.TooltipOptions = {
    valueDecimals: 4,
  };
  data: number[][] = [];
  type: string;
  color: string;
  name: string;
  title: string;
  truncatedData: boolean;
  dateStartTimestamp: number;
  private mChannelIdBaseArray: string[];
  private mChannelIds: string[];
  yAxis: number;
  id: string;
  dataGrouping = { enabled: false };
  gapSize = 60000;
  gapUnit = 'value';
  turboThreshold: 0;
  private mTable: string;
  private trackByArea: boolean;
  private states: any;
  private fillColor: string;
  private showInNavigator: boolean;
  //  .states;

  constructor(
    type: string,
    color: string,
    name: string,
    channelIds: string[],
    tooltip: Highcharts.TooltipOptions = {},
    table: string = 'oneminute',
    title: string = undefined
  ) {
    this.type = type;
    this.color = color;
    this.name = name;
    this.id = name + channelIds.join('');
    this.mChannelIds = channelIds;
    this.mChannelIdBaseArray = [...channelIds];
    Object.assign(this.tooltip, tooltip);
    this.table = table;
    this.title = title;

    if (this.title) {
      this.name += ` (${this.title})`;
    }

    if (this.type === 'arearange') {
      this.fillColor = color;
      this.trackByArea = false;
      this.states = {
        hover: {
          enabled: false,
        },
      };
    }
  }

  public get table(): string {
    return this.mTable;
  }

  public set table(table: string) {
    this.mTable = table;
    if (this.table === 'tenminute') {
      this.gapUnit = 'value';
    }
  }

  public get channelIdBaseArray(): string[] {
    return this.mChannelIdBaseArray;
  }

  public get channelIds(): string[] {
    return this.mChannelIds;
  }

  public setData(dataBySeries: any): void {
    this.truncatedData = dataBySeries[this.table].truncatedData;
    this.dateStartTimestamp = dataBySeries[this.table].dateStart?.valueOf();
    this.data = dataBySeries[this.table].trendDataTable[this.id].newData;
  }

  public setDataGap(granularity: moment.DurationInputArg2, interval?: number): void {
    if (this.table === 'tenminute') {
      if (interval === 1 && granularity === 'minute') {
        this.gapSize = moment.duration(10, granularity).valueOf() as number;
      } else {
        this.gapSize = moment.duration(interval, granularity).valueOf() as number;
      }
    } else {
      this.gapSize = moment.duration(interval, granularity).valueOf() as number;
    }
  }
}
