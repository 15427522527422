import { IEventSeriesOptions } from 'src/app/_shared/classes/chart.interface';
import {
  convertMomentToChartFormat,
  convertMomentToChartFormatGeneric,
} from 'src/app/_shared/helpers/chart-moment-format';
import * as moment from 'moment/moment';

export class EventSeriesOptions implements IEventSeriesOptions {
  id: string;
  type = 'scatter';
  eventType: string;
  color = '#107CE1';
  deviceEventType: string;
  data: { x: number; y: number; eventId?: string }[][] = [];
  eventTypeId: string;
  channelIds = [];
  yValue: number;
  stickyTracking = true;
  dataGrouping = {
    enabled: false,
  };
  tooltip = {
    xDateFormat: `${convertMomentToChartFormatGeneric(
      'LL'
    )}<br/><div style="width: 100%; text-align: center;">${convertMomentToChartFormat(
      moment().creationData().locale.longDateFormat('LTS').replace('ss', 'ss.SSS')
    )}</div><br/>`,
    headerFormat: '{point.key}',
    // eslint-disable-next-line space-before-function-paren
    pointFormatter: function (): string {
      if (this.series.name === 'Others') {
        const otherEvent = this.series.options.data.find((other) => {
          return other[0] === this.x;
        });
        return otherEvent[2];
      }
      return this.series.name;
    },
    findNearestPointBy: 'xy',
  };
  states = {
    hover: {
      enabled: true,
      halo: {
        opacity: 0.25,
        size: 10,
      },
    },
  };
  turboThreshold = 0;
  name: string;
  marker = {
    radius: 7,
    symbol: null,
    width: null,
    height: null,
  };
  yAxis: number;

  constructor(name: string, eventType, symbolName: string, yValue: number, color?: string) {
    this.name = name;
    this.eventType = eventType;
    if (this.name === 'Others') {
      this.marker.radius = 0;
      this.marker.width = 12;
      this.marker.height = 13;
    } else if (this.name === 'High Frequency Impulse') {
      this.marker.radius = 0;
      this.marker.width = 10;
      this.marker.height = 16;
    } else if (this.name === 'Restart') {
      this.marker.width = 18;
      this.marker.height = 18;
    } else if (this.name === 'RVC' || this.name === 'Waveshape') {
      this.marker.width = 16;
      this.marker.height = 16;
    } else if (this.name === 'Note') {
      this.marker.width = 16;
      this.marker.height = 16;
    }
    if (color) {
      this.color = color;
    }
    this.marker.symbol = symbolName;
    this.yValue = yValue;
  }
}
